var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detailsWrap" },
    [
      _c(
        "p",
        {
          staticStyle: {
            "border-bottom": "1px solid #ccc",
            "padding-bottom": "10px",
          },
        },
        [
          _c(
            "router-link",
            { attrs: { to: "/projectEquip-list" } },
            [
              _c("a-icon", { attrs: { type: "rollback" } }),
              _vm._v(" 回到项目设备列表 "),
            ],
            1
          ),
        ],
        1
      ),
      _c("EquipDetailsCon", {
        on: { onProductDetails: _vm.gotoProductDetails },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }