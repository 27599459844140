import { render, staticRenderFns } from "./ProjectDetails.vue?vue&type=template&id=bd4b5abc&scoped=true&"
import script from "./ProjectDetails.vue?vue&type=script&lang=js&"
export * from "./ProjectDetails.vue?vue&type=script&lang=js&"
import style0 from "./ProjectDetails.vue?vue&type=style&index=0&id=bd4b5abc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd4b5abc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/7Fg_oNHL/1/platform-frontend/management-platform/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bd4b5abc')) {
      api.createRecord('bd4b5abc', component.options)
    } else {
      api.reload('bd4b5abc', component.options)
    }
    module.hot.accept("./ProjectDetails.vue?vue&type=template&id=bd4b5abc&scoped=true&", function () {
      api.rerender('bd4b5abc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/project/projectEquip/equipList/ProjectDetails.vue"
export default component.exports